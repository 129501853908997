import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Address } from 'bitbox-sdk';
import memoize from 'memoize-one';
import QRCode from 'qrcode.react';
import Nav from '@bitcoin-portal/navbar';
import Footer from '@bitcoin-portal/footer';
import Newsletter from '@bitcoin-portal/newsletter';
import Input from '@bitcoin-portal/bitcoincom-pkg-components/dist/InputV2';
import Tabs from '@bitcoin-portal/bitcoincom-pkg-components/dist/TabsV2';
import ContentBlock from '@bitcoin-portal/bitcoincom-pkg-components/dist/ContentBlock';
import Paragraph from '@bitcoin-portal/bitcoincom-pkg-components/dist/Paragraph';
import Banner from '@bitcoin-portal/bitcoincom-pkg-components/dist/BannerV2';

import { injectIntl, FormattedHTMLMessage } from 'react-intl';
import {
  Wrapper,
  Address as BCHAddress,
  Content,
  ContentWrapper,
  CustomContentBlock,
} from './styled';
import SEO from './SEO';
import Header from './Header';

const bitboxAddress = new Address();

const convert = memoize(input => {
  try {
    const cashaddr = bitboxAddress.toCashAddress(input);
    const legacy = bitboxAddress.toLegacyAddress(input);
    return { cashaddr, legacy };
  } catch {
    return null;
  }
});

const CashAddressConverter = ({ locale, intl }) => {
  const { messages } = intl;
  const [input, setInput] = useState('');
  const [legacyMode, setLegacyMode] = useState(false);
  const converted = convert(input);
  const tabs = [
    {
      label: intl.formatMessage({
        id: 'cash-address-converter.tabs.cash',
      }),
      action: () => setLegacyMode(false),
    },
    {
      label: intl.formatMessage({
        id: 'cash-address-converter.tabs.legacy',
      }),
      action: () => setLegacyMode(true),
    },
  ];
  return (
    <React.Fragment>
      <SEO />
      <Wrapper>
        <Nav locale={locale} showLang={false} />
        <Header />
        <ContentWrapper>
          <ContentBlock>
            <Paragraph align="center">
              <FormattedHTMLMessage id="cash-address-converter.explanation" />
            </Paragraph>
            <Input
              type="text"
              value={input}
              onChange={e => setInput(e.target.value)}
              placeholder={intl.formatMessage({
                id: 'cash-address-converter.input.placeholder',
              })}
            />
            {converted !== null && (
              <Content>
                <Tabs selectedTab={legacyMode === true ? 1 : 0} tabs={tabs} />
                <BCHAddress>
                  {legacyMode === false ? converted.cashaddr : converted.legacy}
                </BCHAddress>
                <QRCode
                  size={200}
                  value={
                    legacyMode === false ? converted.cashaddr : converted.legacy
                  }
                />
              </Content>
            )}
          </ContentBlock>
        </ContentWrapper>
        <CustomContentBlock hero>
          <Banner
            title={intl.formatMessage({
              id: 'cash-address-converter.banner.title',
            })}
            subtitle={intl.formatMessage({
              id: 'cash-address-converter.banner.text',
            })}
            cta={{
              text: intl.formatMessage({
                id: 'cash-address-converter.banner.button',
              }),
              link: intl.formatMessage({
                id: 'cash-address-converter.banner.link',
              }),
            }}
          />
        </CustomContentBlock>
        <Newsletter
          showLogo
          fullWidth
          title={messages['cash-address-converter.newsletter.title']}
          subtitle={messages['cash-address-converter.newsletter.subtitle']}
          acList={messages['cash-address-converter.newsletter.acList']}
        />
        <Footer locale={locale} />
      </Wrapper>
    </React.Fragment>
  );
};

CashAddressConverter.propTypes = {
  locale: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
    messages: PropTypes.object,
  }).isRequired,
};

export default injectIntl(CashAddressConverter);
